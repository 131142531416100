<template>
  <div id="Login" class="login fullheight" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer fluid class="fullheight"> 
      <CRow class="align-start align-md-center fullheight">
        <CCol cols="12" md="12" :style="!is_finished ? 'margin-top: 30px !important' : 'margin-top: 100px !important'">
          <div class="login__container" style="max-width: 600px !important; margin: auto; padding: 30px 20px;">
            <div class="login__inner" style="max-width: 500px !important;" v-show="is_finished">
              <div class="login__image">
                <img src="/img/logotipo.png" style="width: 300px; margin: 0 105px;" alt="Logo Oscuro"> <br><br> El proceso de recuperación de contraseña ha iniciado.<br><br>
                Hemos enviado un correo de recuperación de contraseña a tu cuenta de correo electrónico, en el cual podrás definir una nueva contraseña de acceso.
              </div>
              <div class="login__form">
                <CRow>
                  <CCol sm="12">
                    <br><CButton type="button" color="primary" style="width: 100%" @click="back">VOLVER</CButton>
                  </CCol>
                </CRow>
              </div>
            </div>
            <div class="login__inner" style="max-width: 500px !important" v-show="!is_finished">
              <div class="login__image">
                <img src="/img/logotipo.png" style="width: 300px; margin: 0 105px;" alt="Logo Oscuro"> <br><br> Ingresa el RFC o Correo Electrónico de la cuenta que deseas recuperar su contraseña.<br><br>
              </div>
              <div class="login__form">
                <CRow>
                  <CCol sm="12">
                    <CInput
                        :isValid="checkIfValid('email')"
                        :value.sync="$v.form.email.$model"
                        label="RFC o Correo Electrónico"
                        placeholder=""
                        invalidFeedback="El RFC o Correo Electrónico es un campo obligatorio."
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <br />
                    <CButton type="button" color="primary" style="width: 250px; float: right;" @click="recovery">RECUPERAR CONTRASEÑA</CButton>
                    <CButton type="button" color="dark" style="width: 120px; float: left;" @click="back">CANCELAR</CButton>
                  </CCol>
                </CRow>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

import router from '../../../router/index'
import login from '../../../services/login';
import general_ws from '../../../services/general';
import store from '../../../store'

import axios from 'axios'

import { validationMixin } from "vuelidate"
import { required, minLength, maxLength, integer, email} from "vuelidate/lib/validators"

export default {
    name: 'ForgetForm',
    components: {

    },
    data () {
        return {
            is_finished: false,
            form: {
                rfc: ''
            },
            submitted: false,
        }
    },
    mounted: async function() {
      
    },
    methods: {
        async recovery () {
            this.$v.$touch();

            this.submitted = true;

            let response = await login.recoveryAccount(this.form);
            
            if(response.type == "success"){
              this.is_finished = true;
            }
            else{
              alert(response.message);
            }
        },
        back(){
          router.push("login");
        },
        checkIfValid (fieldName) {
            const field = this.$v.form[fieldName]
            if (!field.$dirty) {
                return null
            }
            
            return !(field.$invalid)
        },
        showToast(type, message){
            store.commit('toast', {color: type, message: message});
        },
        loading(){
            store.commit('loading');
        },
        loaded(){
            store.commit('loaded');
        }
    },
    computed: {
        formString () { return JSON.stringify(this.form, null, 4) },
        isValid () { return !this.$v.form.$invalid },
        isDirty () { return this.$v.form.$anyDirty },
    },
    mixins: [validationMixin],
    validations: {
        form: {
            email: {
                required
            }
        }
    },
}
</script>